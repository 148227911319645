import { defineMessages } from 'react-intl';

export default defineMessages({
  inputPlaceholder: {
    id: 'app.form.rteAITextInput.inputPlaceholder',
    defaultMessage: 'Ask AI to edit or generate...',
  },
  regenerate: {
    id: 'app.form.rteAITextInput.regenerate',
    defaultMessage: 'Regenerate',
  },
  replace: {
    id: 'app.form.rteAITextInput.replace',
    defaultMessage: 'Replace selection',
  },
  askAI: {
    id: 'app.form.rteAITextInput.askAI',
    defaultMessage: 'Ask AI',
  },
  confirmDiscard: {
    id: 'app.form.rteAITextInput.confirmDiscard',
    defaultMessage: 'Do you want to discard AI response?',
  },
  buttonDiscard: {
    id: 'app.form.rteAITextInput.buttonDiscard',
    defaultMessage: 'Discard',
  },
  buttonCancel: {
    id: 'app.form.rteAITextInput.buttonCancel',
    defaultMessage: 'Cancel',
  },
  AITyping: {
    id: 'app.form.rteAITextInput.AITyping',
    defaultMessage: 'AI is writing',
  },
  editDisabled: {
    id: 'app.form.rteAITextInput.editDisabled',
    defaultMessage: 'You can only edit or generate the last response',
  },
  placeholderMaxLimitReached: {
    id: 'app.containers.SharedList.RecruiterNote.placeholderMaxLimitReached',
    defaultMessage: 'You can only generate up to {maxLimit} AI responses',
  },
  askAIRequestLimit: {
    id: 'app.containers.SharedList.RecruiterNote.askAIRequestLimit',
    defaultMessage:
      'You’ve reached the Ask AI request limit. Please try again later.',
  },
});
