import { defineMessages } from 'react-intl';

export default defineMessages({
  companyName: {
    id: 'app.components.companyName',
    defaultMessage: 'Company name',
  },
  search: {
    id: 'app.components.Base.search.placeholder',
    defaultMessage: 'Search',
  },
  jobs: {
    id: 'app.components.menu.jobs',
    defaultMessage: 'Jobs',
  },

  partners: {
    id: 'app.components.menu.partners',
    defaultMessage: 'Your network',
  },
  partnersManage: {
    id: 'app.components.menu.partnersManage',
    defaultMessage: 'Manage partners',
  },
  partnersFollowers: {
    id: 'app.components.menu.partnersFollowers',
    defaultMessage: 'Followers',
  },
  partnersCircles: {
    id: 'app.components.menu.partnersCircles',
    defaultMessage: 'Follower circles',
  },
  partnersFollowing: {
    id: 'app.components.menu.partnersFollowing',
    defaultMessage: 'Following',
  },
  partnerRequests: {
    id: 'app.components.menu.partnerRequests',
    defaultMessage: 'Partner requests',
  },
  partnerRequestsReceived: {
    id: 'app.components.menu.partnerRequestsReceived',
    defaultMessage: 'Received requests',
  },
  partnerRequestsSent: {
    id: 'app.components.menu.partnerRequestsSent',
    defaultMessage: 'Sent requests',
  },
  partnersCommunities: {
    id: 'app.components.menu.partnersCommunities',
    defaultMessage: 'Communities',
  },
  partnersHub: {
    id: 'app.components.menu.partnersHub',
    defaultMessage: 'Vendor hub',
  },
  partnersBlocked: {
    id: 'app.components.menu.partnersBlocked',
    defaultMessage: 'Blocked vendors',
  },

  savedJobs: {
    id: 'app.components.menu.savedJobs',
    defaultMessage: 'Saved ({counter, number, integer})',
  },
  assignedJobs: {
    id: 'app.components.menu.assignedJobs',
    defaultMessage: 'Assigned ({counter, number, integer})',
  },
  requests: {
    id: 'app.components.menu.requests',
    defaultMessage: 'Requests',
  },
  workOrders: {
    id: 'app.components.menu.workOrders',
    defaultMessage: 'Work orders',
  },
  sharedLists: {
    id: 'app.components.menu.sharedLists',
    defaultMessage: 'Job submission lists',
  },
  marketingLists: {
    id: 'app.components.menu.marketingLists',
    defaultMessage: 'Marketing lists',
  },
  clients: {
    id: 'app.components.menu.clients',
    defaultMessage: 'End clients',
  },
  activeJobs: {
    id: 'app.components.menu.activeJobs',
    defaultMessage: 'All active jobs',
  },
  closedJobs: {
    id: 'app.components.menu.closedJobs',
    defaultMessage: 'All closed jobs',
  },

  overview: {
    id: 'app.components.menu.overview',
    defaultMessage: 'Overview',
  },
  applicants: {
    id: 'app.components.menu.applicants',
    defaultMessage: 'Applicants',
  },
  messages: {
    id: 'app.components.menu.messages',
    defaultMessage: 'All messages',
  },
  offers: {
    id: 'app.components.menu.offers',
    defaultMessage: 'Offers',
  },
  score: {
    id: 'app.components.menu.score',
    defaultMessage: 'Score',
  },
  offer: {
    id: 'app.components.menu.offer',
    defaultMessage: 'Offer',
  },
  documents: {
    id: 'app.components.menu.documents',
    defaultMessage: 'Documents',
  },
  contact: {
    id: 'app.components.menu.contact',
    defaultMessage: 'Contact',
  },
  activity: {
    id: 'app.components.menu.activity',
    defaultMessage: 'Activity',
  },
  adminCompanies: {
    id: 'app.components.menu.adminCompanies',
    defaultMessage: 'Companies',
  },
  adminSubscriptionPlans: {
    id: 'app.components.menu.adminSubscriptionPlans',
    defaultMessage: 'Subscription Plans',
  },
  adminIntegrations: {
    id: 'app.components.menu.adminIntegrations',
    defaultMessage: 'Integrations',
  },
  adminMessages: {
    id: 'app.components.menu.adminMessages',
    defaultMessage: 'Messages',
  },
  adminHotlist: {
    id: 'app.components.menu.adminHotlist',
    defaultMessage: 'Hotlist',
  },
  adminDataAnaylsis: {
    id: 'app.components.menu.adminDataAnaylsis',
    defaultMessage: 'Data Analysis Tool',
  },
  adminOpenAI: {
    id: 'app.components.menu.adminOpenAI',
    defaultMessage: 'OpenAI Prompts',
  },
  candidates: {
    id: 'app.components.menu.candidates',
    defaultMessage: 'All candidates',
  },

  today: {
    id: 'app.components.date.today',
    defaultMessage: 'Today',
  },
  yesterday: {
    id: 'app.components.date.yesterday',
    defaultMessage: 'Yesterday',
  },
  year: {
    id: 'app.components.date.year',
    defaultMessage: 'year',
  },
  years: {
    id: 'app.components.date.years',
    defaultMessage: 'years',
  },
  yearAmount: {
    id: 'app.components.date.yearAmount',
    defaultMessage:
      '{amount, plural, =0 {0 years} one {1 year} =10 {10+ years} other {{amount} years}}',
  },
  yearShort: {
    id: 'app.components.date.yearShort',
    defaultMessage: '{years, plural, =0 {0 yr} one {1 yr} other {{years} yrs}}',
  },
  month: {
    id: 'app.components.date.month',
    defaultMessage: 'month',
  },
  months: {
    id: 'app.components.date.months',
    defaultMessage: 'months',
  },
  monthAmount: {
    id: 'app.components.date.monthAmount',
    defaultMessage:
      '{amount, plural, =0 {0 months} one {1 month} other {{amount} months}}',
  },
  monthShort: {
    id: 'app.components.date.monthShort',
    defaultMessage:
      '{months, plural, =0 {0 mo} one {1 mo} other {{months} mos}}',
  },
  weekAmount: {
    id: 'app.components.date.weekAmount',
    defaultMessage:
      '{amount, plural, =0 {0 weeks} one {1 week} other {{amount} weeks}}',
  },
  day: {
    id: 'app.components.date.day',
    defaultMessage: 'day',
  },
  days: {
    id: 'app.components.date.days',
    defaultMessage: 'days',
  },
  dayAmount: {
    id: 'app.components.date.dayAmount',
    defaultMessage:
      '{amount, plural, =0 {0 days} one {1 day} other {{amount} days}}',
  },
  hour: {
    id: 'app.components.date.hour',
    defaultMessage: 'hour',
  },
  hours: {
    id: 'app.components.date.hours',
    defaultMessage: 'hours',
  },
  hourAmount: {
    id: 'app.components.date.hourAmount',
    defaultMessage:
      '{amount, plural, =0 {0 hours} one {1 hour} other {{amount} hours}}',
  },
  minute: {
    id: 'app.components.date.minute',
    defaultMessage: 'minute',
  },
  minutes: {
    id: 'app.components.date.minutes',
    defaultMessage: 'minutes',
  },
  minutesShort: {
    id: 'app.components.date.minutesShort',
    defaultMessage: 'min',
  },
  minuteAmount: {
    id: 'app.components.date.minuteAmount',
    defaultMessage:
      '{amount, plural, =0 {0 minutes} one {1 minute} other {{amount} minutes}}',
  },
  secondsShort: {
    id: 'app.components.date.secondsShort',
    defaultMessage: 'sec',
  },
  yes: {
    id: 'app.components.options.yes',
    defaultMessage: 'yes',
  },
  no: {
    id: 'app.components.options.no',
    defaultMessage: 'no',
  },
  hidden: {
    id: 'app.components.options.hidden',
    defaultMessage: 'hidden',
  },

  missingFieldsValidation: {
    id: 'app.components.form.missingFieldsValidation',
    defaultMessage: 'There are missing fields',
  },
  emailInvalid: {
    id: 'app.components.form.emailInvalid',
    defaultMessage: 'Email address is invalid',
  },
  passwordWeak: {
    id: 'app.components.form.passwordWeak',
    defaultMessage: 'Please choose a stronger password',
  },
  currentUserEmail: {
    id: 'app.components.form.currentUserEmail',
    defaultMessage: 'Provided email address belongs to current user',
  },
  linkInvalid: {
    id: 'app.components.form.linkInvalid',
    defaultMessage: 'Link is invalid',
  },
  ssnInvalid: {
    id: 'app.components.form.ssnInvalid',
    defaultMessage: 'SSN is invalid: requires 4 digits',
  },
  zipInvalid: {
    id: 'app.components.form.zipInvalid',
    defaultMessage: 'Zip code is invalid: requires 5 digits',
  },
  companyNameInvalid: {
    id: 'app.components.form.companyNameInvalid',
    defaultMessage:
      'The company name contains special characters that are not allowed.',
  },
  companyNameLengthInvalid: {
    id: 'app.components.form.companyNameLengthInvalid',
    defaultMessage: 'Company name requires a minimum of 3 characters',
  },
  firstNameInvalid: {
    id: 'app.components.form.firstNameInvalid',
    defaultMessage:
      'Your first name contains special characters that are not allowed.',
  },
  lastNameInvalid: {
    id: 'app.components.form.lastNameInvalid',
    defaultMessage:
      'Your last name contains special characters that are not allowed.',
  },
  endDateSameOrBeforeStartDate: {
    id: 'app.components.form.endDateSameOrBeforeStartDate',
    defaultMessage: 'End date must be later than start date',
  },
  startDateInThePast: {
    id: 'app.components.form.startDateInThePast',
    defaultMessage: "Start date can't be in the past",
  },
  endDateInThePast: {
    id: 'app.components.form.endDateInThePast',
    defaultMessage: "End date can't be in the past",
  },
  payRateGreaterThanBillRate: {
    id: 'app.components.form.payRateGreaterThanBillRate',
    defaultMessage: 'Pay rate needs to be less than bill rate',
  },
  hoursPerDayGreaterThanHoursPerWeek: {
    id: 'app.components.form.hoursPerDayGreaterThanHoursPerWeek',
    defaultMessage:
      'Hours per day need to be lower than or equal to hours per week',
  },
  hoursPerDayGreaterThanHoursInDay: {
    id: 'app.components.form.hoursPerDayGreaterThanHoursInDay',
    defaultMessage: 'Hours per day need to be lower than or equal to 24',
  },
  hoursPerWeekGreaterThanHoursInWeek: {
    id: 'app.components.form.hoursPerWeekGreaterThanHoursInWeek',
    defaultMessage: 'Hours per week need to be lower than or equal to 168',
  },
  salaryNotGreaterThanZero: {
    id: 'app.components.form.salaryNotGreaterThanZero',
    defaultMessage: 'Salary needs to be greater than 0',
  },
  salaryMinGreaterThanSalaryMax: {
    id: 'app.components.form.salaryMinGreaterThanSalaryMax',
    defaultMessage: 'Maximum salary needs to be greater than minimum salary',
  },
  jobSharingMissingRecipient: {
    id: 'app.components.form.jobSharingMissingRecipient',
    defaultMessage:
      'Please select at least one recipient that the job is shared to',
  },
  locationInvalid: {
    id: 'app.components.form.locationInvalid',
    defaultMessage: 'Please select a valid location from the drop down',
  },
  locationComponentsMissing: {
    id: 'app.components.form.locationComponentsMissing',
    defaultMessage: 'Your selected location needs to include a city',
  },
  emailDisposable: {
    id: 'app.components.Signup.emailDisposable',
    defaultMessage: 'Please use a valid company email address',
  },

  gedEducation: {
    id: 'app.components.education.gedEducation',
    defaultMessage: 'GED',
  },
  highSchoolEducation: {
    id: 'app.components.education.highSchoolEducation',
    defaultMessage: 'High school',
  },
  associatesEducation: {
    id: 'app.components.education.associatesEducation',
    defaultMessage: 'Associates',
  },
  tradeSchoolEducation: {
    id: 'app.components.education.tradeSchoolEducation',
    defaultMessage: 'Trade school',
  },
  bachelorEducation: {
    id: 'app.components.education.bachelor',
    defaultMessage: 'Bachelor',
  },
  masterEducation: {
    id: 'app.components.education.master',
    defaultMessage: 'Master',
  },
  phdEducation: {
    id: 'app.components.education.phd',
    defaultMessage: 'PhD',
  },
  paralegalEducation: {
    id: 'app.components.education.paralegal',
    defaultMessage: 'Paralegal Certificate',
  },
  mdEducation: {
    id: 'app.components.education.md',
    defaultMessage: 'Doctor of Medicine',
  },
  description: {
    id: 'app.scenes.Marketplace.header.description',
    defaultMessage: 'Description',
  },
  supplyCandidate: {
    id: 'app.scenes.Marketplace.header.supplyCandidate',
    defaultMessage: 'Submit candidate',
  },
  suppliedCandidates: {
    id: 'app.scenes.Marketplace.header.suppliedCandidates',
    defaultMessage: 'Submitted candidates ({counter, number, integer})',
  },
  questionsAndAnswers: {
    id: 'app.scenes.Marketplace.header.questionsAndAnswers',
    defaultMessage: 'Q&A',
  },

  abbreviationNotApplicable: {
    id: 'app.components.abbreviationNotApplicable',
    defaultMessage: 'n/a',
  },
  abbreviationHour: {
    id: 'app.components.abbreviationHour',
    defaultMessage: 'hr',
  },
  abbreviationDay: {
    id: 'app.components.abbreviationDay',
    defaultMessage: 'day',
  },
  abbreviationMonth: {
    id: 'app.components.abbreviationMonth',
    defaultMessage: 'month',
  },
  abbreviationYear: {
    id: 'app.components.abbreviationYear',
    defaultMessage: 'yr',
  },
  monthAbbreviation: {
    id: 'app.components.date.monthAbbreviation',
    defaultMessage: 'mm',
  },
  dayAbbreviation: {
    id: 'app.components.date.dayAbbreviation',
    defaultMessage: 'dd',
  },
  chargedHourly: {
    id: 'app.components.chargedHourly',
    defaultMessage: 'charged hourly',
  },
  chargedDaily: {
    id: 'app.components.chargedDaily',
    defaultMessage: 'charged daily',
  },
  availabilityImmediately: {
    id: 'app.components.availabilityImmediately',
    defaultMessage: 'Immediately',
  },
  availabilityWeeks: {
    id: 'app.components.availabilityWeeks',
    defaultMessage: 'In {weeks} weeks',
  },

  permanent: {
    id: 'app.components.workTypes.permanent',
    defaultMessage: 'Direct hire',
  },
  contract: {
    id: 'app.components.workTypes.contract',
    defaultMessage: 'Contract',
  },
  referral: {
    id: 'app.components.workTypes.referral',
    defaultMessage: 'Referral',
  },

  companySize0: {
    id: 'app.components.CompanySize.companySize0',
    defaultMessage: '0-1 employees',
  },
  companySize2: {
    id: 'app.components.CompanySize.companySize2',
    defaultMessage: '2-10 employees',
  },
  companySize11: {
    id: 'app.components.CompanySize.companySize11',
    defaultMessage: '11-50 employees',
  },
  companySize51: {
    id: 'app.components.CompanySize.companySize51',
    defaultMessage: '51-200 employees',
  },
  companySize201: {
    id: 'app.components.CompanySize.companySize201',
    defaultMessage: '201-500 employees',
  },
  companySize501: {
    id: 'app.components.CompanySize.companySize501',
    defaultMessage: '501-1,000 employees',
  },
  companySize1001: {
    id: 'app.components.CompanySize.companySize1001',
    defaultMessage: '1,001-5,000 employees',
  },
  companySize5001: {
    id: 'app.components.CompanySize.companySize5001',
    defaultMessage: '5,001-10,000 employees',
  },
  companySize10001: {
    id: 'app.components.CompanySize.companySize10001',
    defaultMessage: '10,001+  employees',
  },

  atsStageSupplied: {
    id: 'app.components.ATS.atsStageSupplied',
    defaultMessage: 'Submitted',
  },
  atsStageShortlist: {
    id: 'app.components.ATS.atsStageShortlist',
    defaultMessage: 'Shortlist',
  },
  atsStageInterview: {
    id: 'app.components.ATS.atsStageInterview',
    defaultMessage: 'Interview',
  },
  atsStageHired: {
    id: 'app.components.ATS.atsStageHired',
    defaultMessage: 'Hired',
  },
  atsStageDeclined: {
    id: 'app.components.ATS.atsStageDeclined',
    defaultMessage: 'Declined',
  },
  settings: {
    id: 'app.containers.Sidebar.settings',
    defaultMessage: 'Settings',
  },
  yourAccount: {
    id: 'app.containers.Sidebar.yourAccount',
    defaultMessage: 'Your Account',
  },
  subscription: {
    id: 'app.containers.Sidebar.subscription',
    defaultMessage: 'Subscriptions',
  },
  support: {
    id: 'app.containers.Sidebar.support',
    defaultMessage: 'Contact Support',
  },
  helpCenter: {
    id: 'app.containers.Sidebar.helpCenter',
    defaultMessage: 'Help Center',
  },
  logout: {
    id: 'app.containers.Sidebar.logout',
    defaultMessage: 'Logout',
  },
  tagNew: {
    id: 'app.components.Sidebar.tagNew',
    defaultMessage: 'NEW',
  },

  slCandidateStatusNoStatus: {
    id: 'app.components.SharedList.CandidateStatus.slCandidateStatusNoStatus',
    defaultMessage: 'No status',
  },
  slCandidateStatusToReview: {
    id: 'app.components.SharedList.CandidateStatus.slCandidateStatusToReview',
    defaultMessage: 'To review',
  },
  slCandidateStatusRequested: {
    id: 'app.components.SharedList.CandidateStatus.slCandidateStatusRequested',
    defaultMessage: 'CV requested',
  },
  slCandidateStatusRevealed: {
    id: 'app.components.SharedList.CandidateStatus.slCandidateStatusRevealed',
    defaultMessage: 'CV revealed',
  },
  slCandidateStatusInterview: {
    id: 'app.components.SharedList.CandidateStatus.slCandidateStatusInterview',
    defaultMessage: 'Interview',
  },
  slCandidateStatusDeclined: {
    id: 'app.components.SharedList.CandidateStatus.slCandidateStatusDeclined',
    defaultMessage: 'Declined',
  },
});
