import { camelCase, isFunction, isString } from 'lodash';

import { emojisRegex } from './regexes';

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function upperCamelCase(string: string) {
  return capitalizeFirstLetter(camelCase(string));
}

export function removeEmojis(string: string) {
  return isString(string) ? string.replace(emojisRegex, '') : string;
}

export function toWellFormed(string: string) {
  // @ts-expect-error this line checks for the presence of toWellFormed but doesn't call it
  return isFunction(string.toWellFormed)
    ? // @ts-expect-error before using toWellFormed we check for its presence
      string.toWellFormed()
    : removeEmojis(string);
}
