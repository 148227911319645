import { trim } from 'lodash';

import * as styled from './styles';

type LongTextProps = {
  className?: string;
  text: string;
};

/* eslint react/no-danger: 0 */
export const LongText = ({ className, text }: LongTextProps) => (
  <styled.LongText
    data-resume="component.text.long_text"
    className={className}
    dangerouslySetInnerHTML={{
      __html: trim(text),
    }}
  />
);
