import { FormattedMessage } from 'react-intl';

import moment from 'moment';
import { isEmpty } from 'lodash';

import i18n from 'scenes/utils/i18n';

export const dateFormats = {
  US: 'MM/DD/YYYY',
  EUR: 'DD/MM/YYYY',
};
export const DEFAULT_DATE_FORMAT = dateFormats.US;

export const DEFAULT_LONG_DATE_FORMAT = 'dddd, MMMM Do YYYY, h:mm:ss a';

export const formatDateToSave = (value) => moment(value).format('YYYY-MM-DD');
/**
 * @function isValidAsDate
 * @param {string} value
 * @param {string} format
 * @return {boolean} is passed `value` a valid date
 */
export const isValidAsDate = (value, format = 'YYYY-MM-DD', strict) =>
  moment(value, format, strict).isValid();

/**
 * @function formatDate
 * @param {object} intl
 * @param {string} date
 * @param {string} format = "L" as default because it is overwritten with company settings date_format
 * @return {string} Formatted date or n/a
 */
export const formatDate = ({ intl, date, format = 'L' }) => {
  const isValid = !isEmpty(date) && moment(date).isValid();

  if (isValid && format === 'L') return moment(date).format(format);

  if (isValid) {
    return moment.utc(date).local().format(format);
  }

  if (intl) {
    return intl.formatMessage(i18n.abbreviationNotApplicable);
  }

  return null;
};

/**
 * @function formatDayMonth
 * @param {object} intl
 * @param {object} date
 * @return {string} Formatted date, null or n/a
 */
export const formatDayMonth = ({ intl, date }) => {
  if (isEmpty(date)) {
    return intl ? intl.formatMessage(i18n.abbreviationNotApplicable) : null;
  }

  return `${moment()
    .month(date.month - 1)
    .format('MMM')}. ${date.day}`;
};

export const businessDaysDiff = (a, b) => {
  const aDate = moment(a);
  const bDate = moment(b);

  const signal = aDate.unix() < bDate.unix() ? 1 : -1;
  const start = moment.min(aDate, bDate).clone();
  const end = moment.max(aDate, bDate).clone();

  let startOffset = start.day() - 7;
  let endOffset = end.day();

  const endSunday = end.clone().subtract('d', endOffset);
  const startSunday = start.clone().subtract('d', startOffset);
  const weeks = endSunday.diff(startSunday, 'days') / 7;

  startOffset = Math.abs(startOffset);
  if (startOffset === 7) {
    startOffset = 5;
  } else if (startOffset === 1) {
    startOffset = 0;
  } else {
    startOffset -= 2;
  }

  if (endOffset === 6) {
    endOffset -= 1;
  }

  return signal * (weeks * 5 + startOffset + endOffset);
};

export const duration = (dt) =>
  moment.duration(-moment.utc(dt).local().diff(moment()));

export const timeUnitsAgo = (dt, units = 'seconds') =>
  moment().diff(moment.utc(dt).local(), units);

/**
 * @function formattedTimeDifference
 * @param {moment} timestamp1
 * @param {moment} timestamp2
 * @return {node} formatted difference
 */
export const formattedTimeDifference = (timestamp1, timestamp2) => {
  const years = timestamp1.diff(timestamp2, 'years', true);
  if (years >= 1) {
    return (
      <FormattedMessage
        {...i18n.yearAmount}
        values={{ amount: Math.round(years) }}
      />
    );
  }

  const months = timestamp1.diff(timestamp2, 'months', true);
  if (months >= 1) {
    return (
      <FormattedMessage
        {...i18n.monthAmount}
        values={{ amount: Math.round(months) }}
      />
    );
  }

  const days = timestamp1.diff(timestamp2, 'days', true);
  if (days >= 1) {
    return (
      <FormattedMessage
        {...i18n.dayAmount}
        values={{ amount: Math.round(days) }}
      />
    );
  }

  const hours = timestamp1.diff(timestamp2, 'hours', true);
  if (hours >= 1) {
    return (
      <FormattedMessage
        {...i18n.hourAmount}
        values={{ amount: Math.round(hours) }}
      />
    );
  }

  const minutes = timestamp1.diff(timestamp2, 'minutes', true);
  return (
    <FormattedMessage
      {...i18n.minuteAmount}
      values={{ amount: Math.round(minutes) }}
    />
  );
};

export const formatDateRelative = (timestamp) => {
  if (isEmpty(timestamp)) {
    return null;
  }

  const dt = moment.utc(timestamp).local();
  const now = moment.utc().local();

  if (dt.year() === now.year()) {
    return dt.format('MMM DD');
  }

  return formatDate({ date: dt });
};

export const formatDateTimeRelative = (
  timestamp,
  intl,
  opts = { withDate: true }
) => {
  const { withDate } = opts;
  const dt = moment.utc(timestamp).local();
  const now = moment.utc().local();
  const days = now.startOf('day').diff(moment(dt).startOf('day'), 'days');
  let date;

  if (!withDate) {
    return <span>{dt.format('LT')}</span>;
  }

  if (dt.year() !== now.year()) {
    date = dt.format('MMM D, YYYY');
  } else if (days === 0) {
    date = intl.formatMessage(i18n.today);
  } else if (days === 1) {
    date = intl.formatMessage(i18n.yesterday);
  } else if (days <= 7) {
    date = dt.format('dddd');
  } else {
    date = dt.format('ddd, MMM D');
  }

  return (
    <>
      <b>{date}</b>
      <span>, {dt.format('LT')}</span>
    </>
  );
};

export const formatDuration = (seconds, intl, format = 'long') => {
  const momentDuration = moment.duration({ seconds });
  const min = momentDuration.minutes();
  const sec = momentDuration.seconds();
  const minIntl = intl.formatMessage(i18n.minutesShort);
  const secIntl = intl.formatMessage(i18n.secondsShort);

  if (format === 'long') {
    if (min > 0) {
      return `${min} ${minIntl} ${sec} ${secIntl}`;
    }
    return `${sec} ${secIntl}`;
  }
  return `${String(min).padStart(2, '0')}:${String(sec).padStart(
    2,
    '0'
  )} ${minIntl}`;
};

export const formatDateWithTime = (date) =>
  formatDate({ date, format: DEFAULT_LONG_DATE_FORMAT });

export const updateMomentLongDateFormat = ({ format } = {}) => {
  moment.updateLocale(moment.locale(), {
    longDateFormat: {
      L: format || DEFAULT_DATE_FORMAT,
    },
  });
};

export const formatDistanceFromNow = (date, local = true) =>
  local ? moment.utc(date).local().fromNow() : moment.utc(date).fromNow();

export const momentToMonthYear = (date) => {
  if (!date) {
    return null;
  }
  return {
    month: date.month() + 1,
    year: date.year(),
  };
};

export const monthYearToMoment = (monthYear) => {
  if (monthYear.present) {
    return moment().startOf('month');
  }
  if (monthYear.year && monthYear.month) {
    return moment()
      .startOf('year')
      .year(monthYear.year)
      .month(monthYear.month - 1);
  }
  if (monthYear.year) {
    return moment().startOf('year').year(monthYear.year);
  }
  return moment().startOf('month');
};

export const monthYearComparable = (monthYear) => {
  let year = monthYear?.year || 0;
  let month = monthYear?.month || 0;

  if (!monthYear) {
    return '0000-00';
  }

  if (moment.isMoment(monthYear)) {
    year = monthYear.year();
    month = monthYear.month() + 1;
  }

  if (monthYear?.present) {
    return moment().format('YYYY-MM');
  }

  return `${String(year).padStart(4, '0')}-${String(month).padStart(2, '0')}`;
};

export const formatMonthYear = (monthYear, presentLabel) => {
  if (!monthYear) {
    return null;
  }
  if (monthYear.present && presentLabel) {
    return <FormattedMessage {...presentLabel} />;
  }
  if (monthYear.year && !monthYear.month) {
    return monthYear.year;
  }
  if (monthYear.year && monthYear.month) {
    return moment()
      .year(monthYear.year)
      .month(monthYear.month - 1)
      .format('MMM YY');
  }
  return null;
};

export const formatMonthYearDuration = (start, end, intl) => {
  const momentStart = monthYearToMoment(start);
  const momentEnd = monthYearToMoment(end).add(1, 'months');
  const diff = momentEnd.diff(momentStart, 'months');
  const years = Math.floor(diff / 12);
  const months = diff % 12;

  if (years > 0 && months === 0) {
    return intl.formatMessage(i18n.yearShort, { years });
  }
  if (years > 0 && months > 0) {
    return `${intl.formatMessage(i18n.yearShort, {
      years,
    })} ${intl.formatMessage(i18n.monthShort, { months })}`;
  }
  return intl.formatMessage(i18n.monthShort, { months });
};
